@import 'styles/color/color_scheme.scss';
@import 'styles/typography/typography_scheme.scss';

.Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  background-color: $silver;

  &-contents {
    font-family: $NOTO_SANS_KR_MEDIUM, $NOTO_SANS_MEDIUM;
    font-size: $font_content_2;
    &-panel {
      width: 100%;
      // height: 100%;
      align-items: center;
      padding: 60px;
      &-left {
        width: 100%;
        display: flex;
        align-items: center;
        &-logo {
          width: 62px;
          height: 45px;
          -webkit-user-drag: none;
          user-select: none;
        }
        &-link {
          width: 100%;
          height: 100%;
          display: flex;
          margin: auto;
          margin-left: 0;
          justify-content: space-between;
          &-title:hover {
            border-bottom: 1.15px solid $lightGray;
            // cursor: pointer;
          }
          &-title {
            color: $lightGray;
            user-select: none;
            margin-left: 2%;
            margin-right: 2%;
            text-decoration: none;
            user-select: none;
            -webkit-user-drag: none;
          }
          &-center {
            color: $gray;
            user-select: none;
            -webkit-user-drag: none;
          }
          &-email {
            color: $gray;
            padding-top: 4.5px;
          }
        }
      }
    }
  }
}
