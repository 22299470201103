$primary: #0067bc;
$secondary: #f97e6d;
$darkerGray: #1a1a1a;
$darkGray: #292929;
$gray: #686868;
$lightGray: #a1a1a1;
$lighterGray: #cccccc;
$lightestGray: #e2e2e2;
$font_1: #282828;
$font_2: #5c5c5c;
$font_3: #7c7c7c;
$buttonGray: #eeeeee;
$silver: #f1f1f1;
$lightSilver: #f7f7f7;
$shadow: #d2d2d2;
$white: #ffffff;
$success: #69db7c;
$error: #ff6b6b;
$warning: #ffa94d;
$info: #4dabf7;
$background: #f0f2f4;
$meal_morning1: #fff9eb;
$meal_morning2: #fca800;
$meal_afternoon1: #effbff;
$meal_afternoon2: #3dbde5;
$meal_night1: #faf4ff;
$meal_night2: #a252e1;
$merit: #0067bc;
$demerit: #f97e6d;
$black: #000000;
$white: #ffffff