@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.NavBar {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.97);
  transition: top 0.6s ease-in-out;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-nav {
      width: 1080px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        list-style: none;
        &-logo {
          width: 40px;
          position: absolute;
          left: 2.5%;
          top: 24%;
          -webkit-user-drag: none;
          user-select: none;
          &-text {
            display: block;
            text-align: center;
            position: absolute;
            -webkit-user-drag: none;
            user-select: none;
            left: 5%;
            top: 38%;
            text-decoration: none;
            font-size: $font_content_4;
            font-family: $NOTO_SANS_KR_BOLD, $NOTO-SANS_BOLD !important;
            color: $black;
          }
        }
        &-child {
          display: block;
          margin: 0 32px;
          padding: 12px 0 13px;
          &-box {
            display: block;
            margin: 0 32px;
            padding: 12px 0 13px;
            &:hover &-hoverbox {
              height: 98%;
            }
          }
          &-select {
            border-bottom: 2px solid $primary;
          }
          &-link {
            text-decoration: none;
            font-size: $font_content_4;
            font-family: $NOTO_SANS_KR_MEDIUM, $NOTO_SANS_MEDIUM;
            color: black;
            cursor: pointer;
            &:hover &-hoverContent {
              height: 90px;
              width: 90px;
            }
            &-hoverContent {
              overflow: hidden;
              width: 90px;
              transition: 0.7s;
              border-radius: 7%;
              text-decoration: none;
              position: absolute;
              top: 70%;
              height: 0%;
              background-color: $white;
              z-index: 10000;
              &-service {
                text-align: center;
                border-radius: 7%;
                padding: 10px 0 12px;
                justify-content: center;
                text-decoration: none;
                color: $font_3;
                &:hover {
                  background-color: $lightSilver;
                }
              }
              &-history {
                text-align: center;
                border-radius: 7%;
                padding: 10px 0 12px;
                justify-content: center;
                text-decoration: none;
                color: $font_3;
                &:hover {
                  background-color: $lightSilver;
                }
              }
            }
          }
        }
      }
    }
  }
}
