@import 'styles/color/color_scheme.scss';
@import 'styles/typography/typography_scheme.scss';
$B1ND_BLUE: #0066ba;
$B1ND_WHITE: #fafafa;

.MineServiceDiv {
  background-color: $B1ND_BLUE;
  display: flex;
  width: 100%;
  height: 100vh;
  &_imgContainer {
    width: 75%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: -280px;
    z-index: 100;
    overflow: hidden;
    h1 {
      position: absolute;
      margin: 0;
      top: 5rem;
      left: 5rem;
      font-size: 70px;
      font-family: $SPOQA_HANSANS_BOLD !important;
      user-select: none;
    }
    img {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
  &_sloganContainer {
    width: 388px;
    height: 375px;
    margin-top: 3rem;
    margin-left: 3rem;
    user-select: none;
    &_sloganBar {
      width: 342px;
      height: 60px;
      background-color: rgba(0, 0, 0, 0.25);
      margin: 1.5rem;
      &:nth-child(2) {
        width: 135%;
      }
      &:nth-child(3) {
        width: 98%;
      }
      &:nth-child(4) {
        width: 67%;
      }
      &_top {
        margin-top: 2rem;
      }
    }
    &_slogans {
      position: absolute;
      margin-left: 3rem;
      font-size: 70px;
      &_slogan {
        color: $B1ND_WHITE;
        font-family: $SPOQA_HANSANS_BOLD !important;
        margin: 0;
      }
    }
  }
  &_explanations {
    position: absolute;
    left: -0.3%;
    bottom: 10%;
    // position: absolute;
    width: 461px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 30px;
    font-family: $SPOQA_HANSANS_REGULAR !important;
    // bottom: 50px;
    // left: 172px;
    text-align: right;
    color: $B1ND_WHITE;
    padding-right: 2rem;
    & p:first-child {
      font-weight: bold;
    }
    &_explanation {
      margin-top: 1rem;
    }
  }
}
