@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.MineServiceSub {
  &__content {
    background-color: $primary;
    height: 65vh;
    width: 100%;
    color: $white;
    padding: 30px 0px 0px 120px;
    & h1 {
      font-size: 5.5rem;
      margin: 0;
    }
    &-serviceList {
      font-size: 25px;
      display: flex;
      margin-top: 35px;
      &-text {
        width: 500px;
        min-width: 400px;
        float: left;
        list-style: none;
        margin-top: 1%;
        // padding-right: 120px;
        padding-left: 0;
        &-title {
          font-family: $SPOQA_HANSANS_BOLD !important;
          font-size: 28px !important;
          margin-bottom: 2%;
        }
      }

      & li {
        font-size: 4rem;
        &:first-child {
          font-size: 40px;
          margin-bottom: 7%;
        }
      }
      & p {
        margin-bottom: 10%;
      }
    }
  }
  &__img {
    & img {
      position: absolute;
      top: 41%;
      left: 4%;
      width: 90%;
      height: 55%;
    }
  }
}
