@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.TeacherIntro {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  &-square {
    position: absolute;
    width: 141vw;
    height: 800px;
    top: 300px;
    left: -540px;
    background-color: #0066ba;
    transform: rotate(-28.5deg);
    z-index: -1;
  }
  &-wrap {
    width: 88%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-textWrap {
      padding: 50px 0px 0px 60px;
      height: 100vh;
      width: 575px;
      display: flex;
      &-teacherTextWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 94%;
        margin-left: 2.5%;
        &-wrapper {
          &-dodam {
            font-size: 60px;
            font-weight: bold;
            font-family: $NOTO_SANS_KR_BOLD !important;
          }
          &-teacher {
            font-family: $NOTO_SANS_KR_BOLD !important;
            font-size: 70px;
          }
        }
      }
      &-explainWrap {
        width: 500px;
        font-size: 26px;
        color: white;
        font-family: $NOTO_SANS_KR_REGULAR !important;
        line-height: 2.9rem;
        & div:first-child {
          margin-bottom: -5%;
          font-family: $NOTO_SANS_KR_BOLD !important;

        }
      }
    }

    &-right {
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-left: 15%;
      }
    }
  }
}
