@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.IntroPage {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: row-reverse;
  &-textWrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    position: relative;
    left: -100px;

    &-textSection {
      width: 100%;
      height: 770px;
      text-align: right;
    }
    &-mainTitle {
      font-size: 4rem;
      position: relative;
      z-index: 10;
      &-intro {
        font-family: $SPOQA_HANSANS_BOLD !important;
        // font-family: $NOTO_SANS_KR_BOLD !important;
        &-primary {
          // font-family: $SPOQA_HANSANS_BOLD !important;
          font-family: $NOTO_SANS_KR_BOLD !important;
          color: $primary;
        }
      }
    }
    &-middleTitle {
      font-family: $NOTO_SANS_KR_REGULAR !important;
      font-size: 2rem;
      color: #a5a5a5;
      margin: 40px 0px 40px 0px;
      position: relative;
      z-index: 10;
    }
    &-serviceSubTitle {
      font-family: $NOTO_SANS_KR_REGULAR !important;
      font-size: 1.5rem;
      color: #a5a5a5;
      margin-top: 5px;
      position: relative;
      z-index: 10;
    }
  }

  &-imgWrap {
    width: 140%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: -25px;
    left: -31px;

    & img {
      width: 100%;
    }
  }
}
