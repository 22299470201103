@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.AllMockUp {
  width: 100vw;
  height: 100vh;
  &-Background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 999;
    background: -moz-linear-gradient(left, #ffffff 0%, #000000 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #000000 100%);
    // background: linear-gradient(to right, #ffffff 0%, #4e4e4e 100%);
    background: linear-gradient(to right, #ffffff 0%, #000000 100%);
  }
  &-Title {
    position: absolute;
    z-index: 999;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    & > div {
      text-shadow: rgba(0, 0, 0, 0.08) 8px 14px 12px;
      font-size: 5rem;
      background: linear-gradient(to left, #ffffff 22%, #0067bc 77%, #0067bc 84%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // font-weight: bolder;
      font-family: $SPOQA_HANSANS_BOLD !important;
      // & > span {
      //   &:first-child {
      //     color: #000000;
      //   }
      //   &:last-child {
      //     color: white;
      //   }
    }
    // &:first-child{
    //   color: #000000;
    // }
    // &:last-child {
    //   color: white;
    // }
    // }
  }
  &-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
     overflow: hidden;
    & > img {
      position: absolute;
      overflow: hidden;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 70%;
      height: 120%;
    }
  }
}
