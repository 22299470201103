@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.FirstDodamIntro {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background: linear-gradient(to left, #ffffff 33%, #0067bc 160%, #0067bc 100%);
  &-FirstImage {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 4%;
    top: 6%;

    & > img {
      width: 30vw;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 23px 28px;
      border-radius: 4px;
      &:first-child {
        margin-bottom: 16%;
      }
    }
  }
  &-SecondImage {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 37.5%;
    top: 20%;
    & > img {
      width: 30vw;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 23px 28px;
      border-radius: 4px;
      &:first-child {
        margin-bottom: 16%;
      }
    }
  }
  &-ContentWrap {
    position: absolute;
    right: 3%;
    top: 1%;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-Title {
      font-size: 3.75rem;
      margin-top: 10%;
      text-align: end;
      & div {
        font-family: $SPOQA_HANSANS_BOLD !important;
      }
      &-Sub {
        font-size: 3.33rem
      }
      &-Primary {
        font-size: 4rem;
        font-weight: bolder;
        color: $primary;
        font-family: $NOTO_SANS_KR_BLACK !important;
      }
    }
    &-Content {
      & div {
        font-family: $SPOQA_HANSANS_REGULAR !important;
      }
      text-align: end;
      margin-top: 23%;
      font-size: 1.7rem;
      line-height: 2.8rem;
      color: #414141;
      & div:first-child { 
        font-size: 2rem;
        font-family: $NOTO_SANS_KR_BOLD !important;
        color: #0067bc;
      }
    }
  }
}
