@import "styles/color/color_scheme.scss";
@import "styles/typography/typography_scheme.scss";

.MoramMain {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &-ContentWrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: start;
    height: 100vh;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-left: -3%;
    &-TopTitle {
      font-size: 28px;
    }
    &-MainTitle {
      margin-top: 2%;
      margin-bottom: 7%;
      // font-size: 74px;
      font-size: 100px;
      // font-weight: bolder;
      font-family: $NOTO_SANS_KR_BLACK !important;
      color: #5a58da;
      & div:first-child {
        font-size: 70px;
        font-family: $NOTO_SANS_KR_BOLD !important;
      }
    }
    &-Intro {
      font-size: 2rem;
      color: #000;
      word-break: keep-all;
      text-align: end;
      font-family: $NOTO_SANS_KR_REGULAR !important;
      line-height: 4rem;
      margin-top: -7%;
      margin-right: -2%;
      position: relative;
      z-index: 10;

      & strong {
        font-family: $NOTO_SANS_KR_BOLD !important;
        color: #6e6cd3;
      }
    }
  }
  &-ImageWrap {
    position: absolute;
    text-align: center;
    justify-content: flex-end;
    align-items: flex-end;

    & > img {
      width: 78%;
      margin-top: 27%;
      margin-left: 3%;
    }
  }
}