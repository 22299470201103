@import 'styles/color/color_scheme.scss';
@import 'styles/typography/typography_scheme.scss';

.main {
  font-family: $NOTO_SANS_KR_REGULAR, $NOTO_SANS_REGULAR;
  font-size: $font_content_2;
  color: $font_1;
  user-select: none;

  &__content {
    //Top list
    &-toplist {
      list-style: none;
      padding: 0;
      margin-bottom: 8rem;
      // margin-top: 8rem;
      text-align: center;
      &-underline {
        border-bottom: 0.5px solid $lightestGray;
        width: 60rem;
        margin: auto;
      }
      &-wrap {
        width: 80rem;
        margin-right: auto;
        margin-left: auto;
      }
      &-item2 {
        display: inline-block;
        padding: 30px 40px 30px 40px;
        width: 20rem;
      }
      &-item {
        display: inline-block;
        padding: 30px 40px 30px 40px;
        width: 20rem;
        & + & {
          border-left: 1px solid $lightestGray;
        }

        &-header {
          font-size: $font_content_2;
          text-align: start;
          margin-bottom: 1.5rem;
        }
        &-number {
          font-size: $font_title_5;
          font-family: $NOTO_SANS_KR_BOLD, $NOTO_SANS_BOLD;
        }
        &-increase {
          font-size: $font_content_1;
          &-icon {
            color: $primary;
          }
        }
        &-updateDate {
          text-align: start;
          color: $font_3;
          font-family: $NOTO_SANS_LIGHT;
          margin-bottom: 2.5rem;
        }
        &-link {
          text-decoration: none;
          float: left;
          color: $font_1;
          &-icon {
            float: left;
            font-size: $font_content_3;
          }
        }
      }
    }

    //bottomList ㄷ모양 이미지
    &-bottomList {
      padding: 0;
      list-style: none;
      text-align: center;
      width: 100%;
      margin-bottom: 10rem;
      &-wrap {
        width: 80rem;
        margin-left: auto;
        margin-right: auto;
      }
      &-item {
        position: relative;
        display: inline-block;
        // padding-bottom: -1px;
        &:hover &-hoverContent {
          height: 98%;
        }
        &-hoverContent {
          position: absolute;
          bottom: 2%;
          left: 50%;
          transform: translate(-50%, 0.15%);
          background-color: rgba($color: black, $alpha: 0.3);
          color: $white;
          overflow: hidden;
          width: 100%;
          height: 0%;
          z-index: 2;
          transition: 0.3s;
          &-icon {
            display: inline-block;
            color: $primary;
            font-size: $font_title_5;
            vertical-align: middle;
          }
          &-title {
            font-size: $font_title_4;
            font-family: $NOTO_SANS_KR_BOLD, $NOTO-SANS_BOLD;
            height: 2rem;
            margin-top: 8rem;
          }
          &-des {
            display: inline-block;
            font-size: $font_content_2;
            height: 2rem;
            margin-top: 0.8rem;
          }
        }

        &-img {
          width: 26rem;
          height: auto;
          z-index: -1;
        }
      }
      &-textBox {
        display: inline-block;
        width: 52rem;
        font-size: $font_content_3;
        vertical-align: top;
        padding-top: 6rem;
        padding-left: 5rem;
        &-content {
          text-align: start;
          &-title {
            font-size: $font_title_4;
            font-family: $NOTO_SANS_KR_BOLD, $NOTO-SANS_BOLD;
            display: block;
          }
          &-des {
            font-size: $font_content_2;
            text-align: start;
          }
        }
      }
    }

    // endList------------------
    &-endList {
      &-Title {
        text-align: center;
        &-title {
          font-size: $font_title_5;
        }
        &-des {
          font-size: $font_content_2;
        }
      }

      &-list {
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        font-family: $NOTO_SANS_KR_REGULAR, $NOTO_SANS_REGULAR;
        margin-top: 5rem;
        margin-bottom: 10rem;
        &-wrap {
          display: flex;
          // width: 90rem;
          justify-content: center;
          margin-right: auto;
          margin-left: auto;
        }
        &-item {
          text-align: center;
          display: inline-block;
          padding: 0px 50px 20px 50px;
          & + & {
            border-left: 1px solid $lightestGray;
          }
          &-icon {
            font-size: 4rem;
            color: $primary;
          }
          &-title {
            font-size: $font_content_3;
            color: $primary;
          }
          &-des {
            user-select: text;
          }
        }
      }
    }
  }

  &__underline {
    height: 0.125rem;
    background-color: $primary;
    // margin: 5px 0px 5px 0px;
    &-white {
      border: 0.5px solid $white;
      width: 40%;
      margin: 10px 0px 10px 0px;
    }
  }
}
