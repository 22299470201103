 @import "styles/color/color_scheme.scss";
 @import "styles/typography/typography_scheme.scss";

.MineMockUp {
  width: 100%;
  height: 100vh;
  display: flex;
  &-imgWrap {
    position: absolute;
    bottom: -20%;
    left: -20%;
    & > img {
      width: 66.5%;
      margin-left: 6%;
      z-index: -1;
    }
  }
  &-textWrap {
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 50px 80px 0px 0px;
    &-topTitle {
      font-size: 5rem;
      text-align: right;
      font-weight: bold;
      color: #5a58da;
      margin-top: 3%;
      font-family: $NOTO_SANS_KR_BLACK !important;
    }
    &-underTitle {
      font-size: 26px;
      margin-top: 4%;
      text-align: right;
      color: $gray;
      line-height: 3.4rem;
      font-family: $NOTO_SANS_KR_REGULAR !important;
      & br:first-child {
        height: 1px;
      }
      & h4 {
        color: $darkerGray;
        font-size: 2.5rem;
        font-family: $NOTO_SANS_KR_BOLD !important;
      }
      &-first {
        margin-top: 2%;
        margin-bottom: -0.7%;
      }
      &-second {
        margin-top: 2%;
        margin-bottom: -0.7%;
      }
      &-third {
        margin-top: 1%; 
        & strong {
          font-family: $NOTO_SANS_KR_BOLD !important;
          color: #5a58da;
        }
      }
    }
  }
}
