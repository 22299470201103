.ServiceIntroduce {
  background-color: #f8f8f8;
  overflow: hidden !important;
  #root {
    overflow: hidden !important;
  }

  ul:nth-child(1) {
    transform: translate(-50%, -50%);
  }
  &-button {
    display: absolute;
    bottom: 2rem;
    right: 2rem;
    position: fixed;
    z-index: 100;
    width: 50px;
    height: 50px;
    outline: none;
    border: 0;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    box-shadow: 0px 29px 50px rgba(0, 0, 0, 0.23);

    transition: all 0.25s ease;
    & > * {
      font-size: 20px;
    }
    &:hover {
      background-color: black;
      & > * {
        color: white;
      }
    }
  }
}

.primary {
  color: #0066ba;
}
