@import 'styles/color/color_scheme.scss';
@import 'styles/typography/typography_scheme.scss';

@mixin flexStyle() {
  display: flex;
  display: -webkit-flex;
}

.TeacherUnder {
  width: 100%;
  height: 100vh;
  @include flexStyle();
  justify-content: center;
  align-items: center;

  &-wrap {
    width: 100%;
    height: 100%;
    @include flexStyle();
    align-items: center;

    &-imgWrap {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      & > img {
        width: 100%;
        margin-left: 11%;
      }
    }

    &-textWrap {
      width: 30%;
      margin-left: -2.6%;
      @include flexStyle();
      flex-direction: column;
      -ms-flex-direction: column;
      justify-content: space-between;
      height: 550px;
      &-topTitle {
        font-size: 50px;
        font-weight: bold;
        font-family: $NOTO_SANS_KR_BOLD !important;
        & div:last-child {
          color: #0067BC;
          font-family: $NOTO_SANS_KR_BLACK !important;
        }
      }
      &-underTitle {
        font-size: 25px;
        font-family: $SPOQA_HANSANS_REGULAR !important;
        line-height: 2.2rem;
        margin-bottom: -5%;

        & div:first-child {
          color:#0067BC;
          font-weight: bolder;
        }
      }
    }
  }
}
