@import 'styles/color/color_scheme.scss';

.TeamPicture {
  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    & img {
      width: 100%;
      height: 100vh;
    }
  }

  &__content {
    display: inline;
    color: $white;
    font-size: 20px;
    position: absolute;
    bottom: 5%;
    left: 5%;
    & h1 {
      font-size: 42px;
    }
    & ul {
      list-style: none;
      float: left;
      padding: 0;
      line-height: 2.5;
    }
    & a {
      color: $white;
    }
  }
}
