@import 'styles/color/color_scheme.scss';
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import 'styles/typography/typography_scheme.scss';

* {
  box-sizing: border-box;
  font-family: $SPOQA_HANSANS_REGULAR !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: auto !important;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.34;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}
